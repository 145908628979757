import React, { useState, useEffect } from "react";
import { FiEdit3 } from "react-icons/fi";
import CountUp from "../components/CountUp";
const Review = ({
  setCurrentNavbarIndex,
  currentNavbarIndex,
  selectedValues,
  setSelectedValues,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let total = 0;
    selectedValues[0].forEach((element) => {
      element[1].forEach((item) => {
        if (item.select) {
          if (!isNaN(item.price)) {
            total += Number(item.price);
          }
        }
      });
    });
    selectedValues[1].forEach((element) => {
      element[1].forEach((item) => {
        if (item.select) {
          if (!isNaN(item.price)) {
            if (item.multi) {
              total += Number(item.price) * Number(item.count);
            } else {
              total += Number(item.price);
            }
          }
        }
      });
    });
    setTotalPrice(total);
  }, [selectedValues]);

  return (
    <>
      <div>
        <div className="w-full lg:hidden text-xl border-t border-b border-gray-400 p-4 special-font-bold">
          SUMMARY
        </div>
        <div className="hidden lg:inline">
          <h5 className="special-font-light">ORDER</h5>
          <h5
            className="special-font-light text-xs"
            style={{ letterSpacing: "0px" }}
          >
            SUMMARY
          </h5>
        </div>
        <div className="p-4 lg:p-0">
          {selectedValues[0].map((item, index) => {
            return (
              <div key={index} className="mt-4 pb-4 border-b">
                <h4 className="special-font-bold">{item[0][0]}</h4>
                <div
                  className="flex special-font-light ml-4 items-center justify-between"
                  style={{ letterSpacing: "0px" }}
                >
                  <div className="flex items-center pr-4 flex-1 justify-between">
                    <p>{item[1]?.filter((value, i) => value.select)[0].name}</p>
                    <div
                      className="pl-2 cursor-pointer hidden lg:block"
                      onClick={() => {
                        setCurrentNavbarIndex(index);
                      }}
                    >
                      <FiEdit3 />
                    </div>
                  </div>
                  <div
                    className="text-sm special-font-bold flex w-20"
                    style={{ letterSpacing: "0px" }}
                  >
                    {!isNaN(
                      item[1].filter((value, i) => value.select)[0].price
                    ) && "€"}
                    {!isNaN(
                      item[1].filter((value, i) => value.select)[0].price
                    ) ? (
                      <CountUp
                        endNum={
                          item[1].filter((value, i) => value.select)[0].price
                        }
                      />
                    ) : (
                      item[1].filter((value, i) => value.select)[0].price
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {selectedValues[1].map((item, index) => {
            return (
              <div
                key={index}
                className="mt-4 pb-4 border-b"
                style={{
                  display:
                    item[1].filter((val) => val.select).length > 0
                      ? "block"
                      : "none",
                }}
              >
                <h4 className="special-font-bold">{item[0]}</h4>
                {item[1].map((val, ind) => {
                  return (
                    <div
                      key={ind}
                      className="special-font-light ml-4 mb-4 items-center justify-between"
                      style={{
                        letterSpacing: "0px",
                        display: val.select ? "flex" : "none",
                      }}
                    >
                      <div className="flex items-center pr-4 flex-1 justify-between">
                        <p>
                          {val.multi && val.count > 1 && `${val.count} x `}
                          {val.name}
                        </p>
                        <div
                          className="pl-2 cursor-pointer hidden lg:block"
                          onClick={() => {
                            setCurrentNavbarIndex(index + 5);
                          }}
                        >
                          <FiEdit3 />
                        </div>
                      </div>
                      <div
                        className="text-sm special-font-bold flex w-20"
                        style={{ letterSpacing: "0px" }}
                      >
                        {!isNaN(val.price) && "€"}
                        {!isNaN(val.price) ? (
                          <CountUp endNum={val.price} />
                        ) : (
                          val.price
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className="mb-6 pb-4 border-b">
            <h4 className="special-font-bold mt-4">PURCHASE PRICE:</h4>
            <div className="ml-4 text-3xl flex">
              <p>€</p>
              <CountUp endNum={totalPrice} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
