import React from "react";
import CountUp from "../components/CountUp";
const DecideType3 = ({
  setAnimationStartEnd,
  selectedValues,
  setSelectedValues,
}) => {
  // console.log(selectedValues[0][0][1]);

  return (
    <>
      <h4 className="text-lg mt-3 ml-4 lg:ml-1">
        <span className="special-font-bold">{selectedValues[0][3][0][0]}</span>
        <span className="ml-2 special-font-light">SELECTION</span>
      </h4>
      {selectedValues[0][3][0][1] && (
        <h4 className="text-xs ml-4 lg:ml-1 mb-4">
          <span className="special-font-light" style={{ letterSpacing: "0px" }}>
            MANDATORY SELECTION
          </span>
        </h4>
      )}
      <div className="flex w-full flex-wrap justify-around items-center">
        {selectedValues[0][3][1].map((item, index) => {
          if (
            selectedValues[0][1][1]?.filter((filt) => filt?.select)?.[0]?.id !==
              item?.id ||
            selectedValues[0][1][1]?.filter((filt) => filt?.select)?.[0]
              ?.type !== item?.type
          ) {
            return;
          }
          return (
            <div
              className="rounded-xl w-full mb-8 mr-2 lg:mr-0 cursor-pointer"
              key={index}
              style={{
                maxWidth: "",
                width: "200px",
                border: `${item?.select ? "2px" : "1px"} solid ${
                  item?.select ? "#252d56" : "rgba(0,0,0,0.3)"
                }`,
              }}
              onClick={() => {
                // setAnimationStartEnd(true);
                let x = [...selectedValues];
                x[0][3][1] = x[0][3][1].map((boat, i) => {
                  if (index === i) {
                    return { ...boat, select: true };
                  } else {
                    return { ...boat, select: false };
                  }
                });

                setSelectedValues(x);
                setTimeout(() => {
                  setAnimationStartEnd(false);
                }, 200);
              }}
            >
              <div className="w-full">
                <div className="flex w-full justify-around transform translate-y-6">
                  <img src={item?.image} alt="" className="w-48" />
                </div>
                <div className="w-full flex justify-start transform translate-x-3 mt-8">
                  <h3
                    className="special-font-bold"
                    style={{
                      letterSpacing: "0px",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    {item?.name}
                  </h3>
                </div>
              </div>
              <div className="flex justify-start pl-3 pt-4 w-full">
                <div
                  className="special-font-light w-32 flex"
                  style={{ letterSpacing: "0px" }}
                >
                  {!isNaN(item.price) && "€"}
                  {!isNaN(item.price) ? (
                    <CountUp endNum={item.price} />
                  ) : (
                    item.price
                  )}
                </div>
              </div>
              <div className="flex w-full justify-center">
                <div
                  style={{
                    border: `${item?.select ? "2px" : "1px"} solid ${
                      item?.select ? "#252d56" : "rgba(0,0,0,0.3)"
                    }`,
                  }}
                  className="w-12 h-6 bg-white flex justify-center items-center rounded-lg transform translate-y-3"
                >
                  {item?.select && (
                    <div
                      className="w-8 h-3 rounded"
                      style={{ background: "#252d56" }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DecideType3;
