import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { GrFormClose } from "react-icons/gr";

const EmailSentModal = ({ isOpen, setIsOpen, success }) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto bg-gray-900 bg-opacity-50"
          onClose={closeModal}
          style={{ zIndex: "1000" }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <div className="flex justify-end w-full transform h-4 -translate-y-6 translate-x-6">
                    <button
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <GrFormClose className="text-5xl opacity-50" />
                    </button>
                  </div>

                  <div className="flex justify-center px-4 mt-2 pb-4">
                    <h3 className="text-gray-600 capitalize font-bold mt-4 text-3xl">
                      {success ? (
                        <p className="text-green-600">Successful!</p>
                      ) : (
                        <p className="text-red-600">Error!</p>
                      )}
                    </h3>
                  </div>
                </Dialog.Title>

                <p className="text-xl text-gray-700 px-6 mt-4">
                  {success ? (
                    <p className="text-center">
                      We now have your information. We will contact with you
                      soon. Thanks!
                    </p>
                  ) : (
                    <p className="text-center">
                      A problem occured. Please try again later!
                    </p>
                  )}
                </p>

                <div className="mt-10 flex w-full justify-center flex-wrap">
                  <button
                    onClick={closeModal}
                    className="mx-8 my-4 capitalize disabled:opacity-50 rounded-full py-1 px-8 text-xl text-white bg-gray-600 hover:opacity-75 font-semibold"
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EmailSentModal;
