import React, { useEffect } from "react";
import navbarItems from "../datas/navbarItems";
const NavbarTop = ({
  currentNavbarIndex,
  setCurrentNavbarIndex,
  setAnimationStartEnd,
  currentNavbarActiveIndex,
  setCurrentNavbarActiveIndex,
}) => {
  useEffect(() => {
    if (currentNavbarIndex !== currentNavbarActiveIndex) {
      setCurrentNavbarActiveIndex(currentNavbarIndex);
    }
    // eslint-disable-next-line
  }, [currentNavbarIndex]);

  return (
    <>
      <div className="w-full h-16"></div>
      <div className="fixed bg-white top-0 z-10 left-0 w-full h-16 border-b-2 border-gray-300 flex flex-col lg:flex-row justify-center lg:justify-start items-center">
        <img
          src="/images/companyLogo.png"
          alt=""
          className="w-40 mx-8"
          style={{ filter: "invert(1)" }}
        />
        <div className="lg:flex h-full hidden">
          {navbarItems
            .filter((item, index) => index < 9)
            .map((item, index) => {
              return (
                <button
                  key={index}
                  className="font-semibold cursor-pointer h-full pt-4 absolute lg:relative hidden lg:flex items-center border-b-4"
                  style={{
                    marginRight: "14px",
                    marginLeft: "15px",
                    fontSize: "13px",
                    borderColor:
                      currentNavbarActiveIndex === index
                        ? "#1f2937"
                        : "rgba(0,0,0,0)",
                  }}
                  onClick={() => {
                    setAnimationStartEnd(true);
                    setCurrentNavbarActiveIndex(index);
                    setTimeout(() => {
                      setCurrentNavbarIndex(index);
                      setAnimationStartEnd(false);
                    }, 200);
                  }}
                >
                  {item}
                </button>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default NavbarTop;
