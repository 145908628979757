import React from "react";
import SelectType1 from "../components/SelectType1";

const Select = ({ currentNavbarIndex, selectedValues, setSelectedValues }) => {
  return (
    <div className="w-full">
      <div className="hidden lg:block w-full">
        {currentNavbarIndex > 4 && currentNavbarIndex < 8 && (
          <SelectType1
            currentNavbarIndex={currentNavbarIndex}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        )}
      </div>
      <div className="lg:hidden block">
        <SelectType1
          currentNavbarIndex={5}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
        <SelectType1
          currentNavbarIndex={6}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
        <SelectType1
          currentNavbarIndex={7}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
      </div>
    </div>
  );
};

export default Select;
