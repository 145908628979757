import React from "react";
import Review from "../components/Review";
const Informations = ({
  setCurrentNavbarIndex,
  currentNavbarIndex,
  selectedValues,
  setSelectedValues,
  setValues,
  values,
}) => {
  return (
    <>
      <div className="lg:-mb-8 lg:h-full">
        <div className="lg:h-full flex w-full">
          <div className="flex-1 hidden border-r border-gray-400 lg:flex justify-center flex-col items-center">
            <div className="w-full justify-center text-center mb-8 special-font-bold text-3xl">
              ENTER YOUR DETAILS
            </div>
            <div className="w-full flex max-w-3xl flex-wrap justify-center">
              {values.map((item, index) => {
                return (
                  <div key={index} className="flex flex-col p-4 w-1/2">
                    <label htmlFor={item.id}>{item.title}</label>
                    <input
                      type={item.type}
                      id={item.id}
                      value={item.value}
                      className="border border-gray-400 rounded-lg h-12 px-2"
                      onChange={(e) => {
                        let x = [...values];
                        x[index].value = e.target.value;
                        setValues(x);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="lg:hidden w-full flex flex-col items-center mb-4">
            <div className="w-full justify-center text-center special-font-bold text-xl mt-8">
              ENTER YOUR DETAILS
            </div>
            <div
              className="w-full flex max-w-3xl flex-wrap justify-center"
              id={"myInfo"}
            >
              {values.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col p-4 w-11/12 sm:w-1/2"
                  >
                    <label htmlFor={item.id}>{item.title}</label>
                    <input
                      type={item.type}
                      id={item.id}
                      value={item.value}
                      className="border border-gray-400 rounded h-12 px-2"
                      onChange={(e) => {
                        let x = [...values];
                        x[index].value = e.target.value;
                        setValues(x);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="lg:w-1/2 hidden lg:block lg:max-w-lg pt-8 px-4 h-full overflow-auto">
            <Review
              currentNavbarIndex={currentNavbarIndex}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              setCurrentNavbarIndex={setCurrentNavbarIndex}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Informations;
