import React, { useEffect, useState } from "react";
import { useCountUp } from "react-countup";

const CountUp = ({ startNum, endNum, duration }) => {
  const countUpRef = React.useRef(null);
  const [firstControl, setFirstControl] = useState(false);
  // eslint-disable-next-line
  const { pauseResume, reset, update } = useCountUp({
    ref: countUpRef,
    start: startNum || 0,
    end: endNum,
    delay: 0,
    duration: duration || 1,
    separator: ",",
    // onReset: () => console.log("Resetted!"),
    // onUpdate: () => console.log("Updated!"),
    // onPauseResume: () => console.log("Paused or resumed!"),
    // onStart: ({ pauseResume }) => console.log(pauseResume),
    // onEnd: ({ pauseResume }) => console.log(pauseResume),
  });

  useEffect(() => {
    if (firstControl) {
      update(endNum);
    } else {
      setFirstControl(true);
    }
    // eslint-disable-next-line
  }, [endNum]);

  return (
    <>
      <div>
        <div ref={countUpRef} />
      </div>
    </>
  );
};

export default CountUp;
