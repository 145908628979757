import React from "react";
import Decide from "../screens/Decide";
import Select from "../screens/Select";
import Informations from "../screens/Informations";
import Review from "../components/Review";

const Configurator = ({
  currentNavbarIndex,
  setCurrentNavbarIndex,
  setAnimationStartEnd,
  animationStartEnd,
  pageHeight,
  selectedValues,
  setSelectedValues,
  setValues,
  values,
}) => {
  return (
    <>
      <div
        className={`hidden lg:block ${
          !animationStartEnd ? "animation-appear" : "animation-disappear"
        }`}
        style={{
          height: pageHeight - 176,
          minHeight: 600,
        }}
      >
        {(currentNavbarIndex < 5 || currentNavbarIndex === 8) && (
          <Decide
            currentNavbarIndex={currentNavbarIndex}
            setAnimationStartEnd={setAnimationStartEnd}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            setCurrentNavbarIndex={setCurrentNavbarIndex}
          />
        )}
        {currentNavbarIndex > 4 && currentNavbarIndex < 8 && (
          <Select
            currentNavbarIndex={currentNavbarIndex}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        )}

        {currentNavbarIndex === 9 && (
          <Informations
            currentNavbarIndex={currentNavbarIndex}
            setAnimationStartEnd={setAnimationStartEnd}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            setCurrentNavbarIndex={setCurrentNavbarIndex}
            setValues={setValues}
            values={values}
          />
        )}
      </div>
      <div
        id="configurator"
        className={`lg:hidden ${
          !animationStartEnd ? "animation-appear" : "animation-disappear"
        }`}
        style={
          {
            // height: pageHeight - 176,
            // minHeight: 600,
          }
        }
      >
        <Decide
          currentNavbarIndex={currentNavbarIndex}
          setAnimationStartEnd={setAnimationStartEnd}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setCurrentNavbarIndex={setCurrentNavbarIndex}
        />

        <Select
          currentNavbarIndex={currentNavbarIndex}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />

        <Review
          currentNavbarIndex={currentNavbarIndex}
          setAnimationStartEnd={setAnimationStartEnd}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setCurrentNavbarIndex={setCurrentNavbarIndex}
        />

        <Informations
          currentNavbarIndex={currentNavbarIndex}
          setAnimationStartEnd={setAnimationStartEnd}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setCurrentNavbarIndex={setCurrentNavbarIndex}
          setValues={setValues}
          values={values}
        />
      </div>
    </>
  );
};

export default Configurator;
