import React, { useEffect, useState } from "react";
import CountUp from "../components/CountUp";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import EmailSentModal from "../components/EmailSentModal";

const axios = require("axios");

const Footer = ({
  currentNavbarIndex,
  setCurrentNavbarIndex,
  selectedValues,
  setValues,
  values,
}) => {
  const [loading, setLoading] = useState(false);
  const [showEmailSentModal, setShowEmailSentModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const changeNavigation = (way) => {
    if (way === "-" && currentNavbarIndex > 0) {
      setCurrentNavbarIndex(currentNavbarIndex - 1);
    }
    if (way === "+" && currentNavbarIndex < 9) {
      setCurrentNavbarIndex(currentNavbarIndex + 1);
    }
  };

  useEffect(() => {
    setSuccess(false);
  }, [values, selectedValues]);

  const [totalPrice, setTotalPrice] = useState(0);

  const sendInformationMail = () => {
    // console.log(values);
    // console.log(selectedValues);
    let mailHtml = "";
    values.forEach((item, index) => {
      // eslint-disable-next-line
      mailHtml = mailHtml + " " + `<p>${item?.title}: ${item?.value}</p>`;
    });

    mailHtml = mailHtml + `<h1 style="color:red;">SUMMARY</h1>`;

    selectedValues[0].forEach((item, index) => {
      mailHtml =
        mailHtml +
        `<div >
            <h2 style="color:red;">${item[0][0]}</h2>
            <div>
              <div>
                <p>${
                  item[1].filter((value, i) => value.select)[0].name
                }</p>               
              </div>
              <div>
                ${
                  !isNaN(item[1].filter((value, i) => value.select)[0].price)
                    ? "€"
                    : ""
                }
                ${
                  !isNaN(item[1].filter((value, i) => value.select)[0].price)
                    ? item[1].filter((value, i) => value.select)[0].price
                    : item[1].filter((value, i) => value.select)[0].price
                }
              </div>
            </div>
          </div>`;
    });

    selectedValues[1].forEach((item, index) => {
      mailHtml =
        mailHtml +
        `<div
            style="
              display:
                ${
                  item[1].filter((val) => val.select).length > 0
                    ? "block"
                    : "none"
                };"
            
          >
            <h2 style="color:red;">${item[0]}</h2>`;

      item[1].forEach((val, ind) => {
        mailHtml =
          mailHtml +
          `
                <div
                  style="
                    display: ${val.select ? "flex" : "none"};
                  "
                >
                <div>
                  <p>
                  ${!isNaN(val.price) ? "€" : ""}
                  ${!isNaN(val.price) ? val.price : val.price}
                  </p>
                  </div>
                  <div style="width:10px;"></div>
                  <div>
                    <p>
                      ${
                        val?.multi && val?.count > 1
                          ? " " +
                            "(€" +
                            +val.count * +val.price +
                            ") " +
                            val.count +
                            "x"
                          : ""
                      }
                      ${"- "}${val.name}
                    </p>
                  </div>
                  
                </div>
              `;
      });
      mailHtml = mailHtml + `</div>`;
    });

    mailHtml =
      mailHtml +
      `<div>
            <h1 style="color:red;">PURCHASE PRICE:</h1>
            <div style="display:flex;">
              <h1>€</h1>
              <h1>
              ${totalPrice}
              </h1>
            </div>
          </div>`;

    mailHtml =
      mailHtml +
      `
          <div style="margin-top:25px; margin-bottom:25px">
            <p>Best Regards</p>
            <p>Samba Powerboats</p>
          </div>
          `;

    // console.log(mailHtml);
    const urlServer = "https://redterrex.onrender.com";
    // const urlServer = "http://localhost:4000";
    setLoading(true);
    axios({
      method: "post",
      url: urlServer + "/users/samba-powerboats",
      data: { mailHtml },
    })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setShowEmailSentModal(true);
        setSuccess(true);
        // window.alert("success");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setShowEmailSentModal(true);
        setSuccess(false);
        // window.alert("error");
      });
  };

  useEffect(() => {
    let total = 0;
    selectedValues[0].forEach((element) => {
      element[1].forEach((item) => {
        if (item.select) {
          if (!isNaN(item.price)) {
            total += Number(item.price);
          }
        }
      });
    });
    selectedValues[1].forEach((element) => {
      element[1].forEach((item) => {
        if (item.select) {
          if (!isNaN(item.price)) {
            if (item.multi) {
              total += Number(item.price) * Number(item.count);
            } else {
              total += Number(item.price);
            }
          }
        }
      });
    });
    setTotalPrice(total);
  }, [selectedValues]);

  return (
    <>
      <div className="h-28 w-full"></div>
      <EmailSentModal
        success={success}
        isOpen={showEmailSentModal}
        setIsOpen={setShowEmailSentModal}
      />
      <div className="fixed bg-white bottom-0 left-0 w-full h-28 border-t-2 border-gray-400">
        <div className="flex justify-between items-center h-full">
          <div className="px-4">
            <div>
              <span className="special-font-light text-xs md:text-lg">
                PURCHASE
              </span>{" "}
              <span className="special-font-bold text-xs md:text-lg">
                PRICE
              </span>
              :
            </div>
            <div className="flex text-2xl">
              <p>€</p>
              <CountUp endNum={totalPrice} />
            </div>
          </div>
          <div className="pr-4 md:pr-8 lg:pr-16 flex">
            <button
              className="w-40 h-10 mr-4 special-font-light rounded-lg hidden lg:flex justify-center items-center text-white"
              style={{ background: "#b84e50", fontWeight: "600" }}
              onClick={() => {
                changeNavigation("-");
              }}
            >
              Back
            </button>
            <button
              disabled={loading || (success && currentNavbarIndex === 9)}
              className="w-64 h-10 special-font-light rounded-lg hidden lg:flex justify-center items-center text-white"
              style={{ background: "#3a4166", fontWeight: "600" }}
              onClick={() => {
                if (
                  currentNavbarIndex === 9 &&
                  values.filter((item) => item.value).length > 6
                ) {
                  sendInformationMail();
                } else {
                  if (
                    currentNavbarIndex === 9 &&
                    values.filter((item) => item.value).length < 7
                  ) {
                  } else {
                    changeNavigation("+");
                  }
                }
              }}
            >
              {loading ? (
                <>
                  <AiOutlineLoading3Quarters className="rotating" />
                </>
              ) : (
                <>
                  {currentNavbarIndex === 7
                    ? "Review Your Boat"
                    : currentNavbarIndex === 8 ||
                      (currentNavbarIndex === 8 &&
                        values.filter((item) => item.value).length < 8)
                    ? "Contact Us"
                    : currentNavbarIndex === 9
                    ? success
                      ? "Sent!"
                      : "Send"
                    : "Next"}
                </>
              )}
            </button>
            <button
              disabled={loading || (success && currentNavbarIndex === 9)}
              className="w-32 text-sm h-10 special-font-light rounded-lg flex lg:hidden justify-center items-center text-white"
              style={{ background: "#3a4166", fontWeight: "600" }}
              onClick={() => {
                if (values.filter((item) => item.value).length > 6) {
                  sendInformationMail();
                } else {
                  let x = document.querySelector("#myInfo");
                  window.scrollTo(0, x.offsetTop);
                }
              }}
            >
              {loading ? (
                <>
                  <AiOutlineLoading3Quarters className="rotating" />
                </>
              ) : (
                <>
                  {values.filter((item) => item.value).length > 6 ? (
                    success ? (
                      "Sent!"
                    ) : (
                      "Send"
                    )
                  ) : (
                    <>Contact Us</>
                  )}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
