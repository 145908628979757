import React from "react";

const DecideType0 = ({
  setAnimationStartEnd,
  selectedValues,
  setSelectedValues,
}) => {
  // console.log(selectedValues[0][0][1]);

  return (
    <>
      <h4 className="text-lg mt-3 ml-4 lg:ml-1">
        <span className="special-font-bold">{selectedValues[0][0][0][0]}</span>
        <span className="ml-2 special-font-light">SELECTION</span>
      </h4>
      {selectedValues[0][0][0][1] && (
        <h4 className="text-xs ml-4 lg:ml-1 mb-4">
          <span className="special-font-light" style={{ letterSpacing: "0px" }}>
            MANDATORY SELECTION
          </span>
        </h4>
      )}
      <div className="flex w-full flex-row lg:flex-col flex-wrap justify-center items-center">
        {selectedValues[0][0][1].map((item, index) => {
          return (
            <div
              className="rounded-xl mb-8 mr-0 md:mr-8 lg:mr-0 cursor-pointer"
              key={index}
              style={{
                maxWidth: "90%",
                width: "450px",
                border: `${item?.select ? "2px" : "1px"} solid ${
                  item?.select ? "#252d56" : "rgba(0,0,0,0.3)"
                }`,
              }}
              onClick={() => {
                // setAnimationStartEnd(true);
                let x = [...selectedValues];

                if (!x[0][0][1][index]?.select) {
                  x[0][2][1] = x[0][2][1].map((val, ind) => {
                    return { ...val, select: false };
                  });
                  x[0][3][1] = x[0][3][1].map((val, ind) => {
                    return { ...val, select: false };
                  });
                  x[1].forEach((val, ind) => {
                    val[1].forEach((value, i) => {
                      if (value.class === "all" || value.class === "open") {
                      } else {
                        x[1][ind][1][i] = {
                          ...x[1][ind][1][i],
                          select: false,
                        };
                      }
                    });
                  });
                }
                let botId = 0;
                let done = false;
                x[0][0][1] = x[0][0][1].map((boat, i) => {
                  if (i === index) {
                    botId = boat?.id;
                    return { ...boat, select: true };
                  } else {
                    return { ...boat, select: false };
                  }
                });
                x[0][1][1] = x[0][1][1].map((boat, i) => {
                  if (boat?.id === botId && !done) {
                    done = true;
                    return { ...boat, select: true };
                  } else {
                    return { ...boat, select: false };
                  }
                });
                x[0][2][1] = x[0][2][1].map((boat, i) => {
                  if (i === 0) {
                    return { ...boat, select: true };
                  } else {
                    return { ...boat, select: false };
                  }
                });
                done = false;
                x[0][3][1] = x[0][3][1].map((boat, i) => {
                  if (item?.id === boat?.id && !done) {
                    done = true;
                    return { ...boat, select: true };
                  } else {
                    return { ...boat, select: false };
                  }
                });

                setSelectedValues(x);
                setTimeout(() => {
                  setAnimationStartEnd(false);
                }, 200);
              }}
            >
              <div className="flex w-full justify-center">
                <img src={item?.image} alt="" className="w-80" />
              </div>
              <div className="w-full flex justify-center">
                <h3
                  className="special-font-bold"
                  style={{ letterSpacing: "1px", fontWeight: "600" }}
                >
                  {item?.name}
                </h3>
              </div>
              <div className="flex w-full justify-center">
                <div
                  style={{
                    border: `${item?.select ? "2px" : "1px"} solid ${
                      item?.select ? "#252d56" : "rgba(0,0,0,0.3)"
                    }`,
                  }}
                  className="w-12 h-6 bg-white flex justify-center items-center rounded-lg transform translate-y-3"
                >
                  {item?.select && (
                    <div
                      className="w-8 h-3 rounded"
                      style={{ background: "#252d56" }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DecideType0;
