const selectItems = [
  [
    ["FUNCTIONALITY"],
    [
      {
        name: "Bow thruster Sidepower SE40",
        specifications: "",
        price: "3800",
        image: "/images/functionality/func1.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      // {
      //   name: "Trim tab automatic control",
      //   specifications: "",
      //   price: "TBD",
      //   image: "/images/functionality/func2.jpg",
      //   type: "0",
      //   select: false,
      //   class: "all",
      // },
      {
        name: "Folding anchor system, including SS Ultra 12kg anchor and 40 mt 8mm galvanised chain",
        specifications: "",
        price: "3500",
        image: "/images/functionality/func3.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Led courtesy lights in cockpit 12pcs",
        specifications: "",
        price: "450",
        image: "/images/functionality/func4.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Led bar hardtop lights",
        specifications: "",
        price: "250",
        image: "/images/functionality/func5.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Hotwater System",
        specifications: "",
        price: "1500",
        image: "/images/functionality/func6.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Portable Honda EU22 genset with custom housing",
        specifications: "",
        price: "2000",
        image: "/images/functionality/func7.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Radar tower",
        specifications: "",
        price: "1200",
        image: "/images/functionality/func8.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Water toy tow bar",
        specifications: "",
        price: "1450",
        image: "/images/functionality/func9.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Wetbar",
        specifications: "",
        price: "3300",
        image: "/images/functionality/func10.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Antifouling Bottom Paint",
        specifications: "",
        price: "3000",
        image: "/images/functionality/func11.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Seakeeper 2 Gyro Stabiliser complete with lithium battery system",
        specifications: "",
        price: "30000",
        image: "/images/functionality/func12.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Gravity Plus Shock Mitigation Seats",
        specifications: "",
        price: "2000",
        image: "/images/functionality/func13.jpg",
        type: "0",
        multi: true,
        count: 0,
        select: false,
        class: "all",
      },
      {
        name: "Motorised Ragtop Open",
        specifications: "",
        price: "7500",
        image: "/images/functionality/func14.jpg",
        type: "0",
        select: false,
        class: "open",
      },
      {
        name: "Special boat fender/collar",
        specifications: "",
        price: "12000",
        image: "/images/functionality/func15.jpg",
        type: "0",
        select: false,
        class: "all",
      },
    ],
  ],
  [
    ["ELECTRONIC"],
    [
      {
        name: "Raymarine Axiom 12 + RV100 + MAP",
        specifications: "",
        price: "4725",
        image: "/images/electronic/elec1.jpg",
        type: "1",
        select: false,
        class: "all",
      },
      {
        name: "Raymarine Axiom Pro 12 + 1kW Chirp in Hull Transducer+ MAP",
        specifications: "",
        price: "7938",
        image: "/images/electronic/elec2.jpg",
        type: "1",
        select: false,
        class: "all",
      },
      {
        name: "Ray53 VHF",
        specifications: "",
        price: "1200",
        image: "/images/electronic/elec3.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Doppler Radar",
        specifications: "",
        price: "3232",
        image: "/images/electronic/elec4.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Evolution Hydraulic autopilot",
        specifications: "",
        price: "3360",
        image: "/images/electronic/elec5.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Fusion Music System",
        specifications: "",
        price: "1650",
        image: "/images/electronic/elec6.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      // {
      //   name: "Samba11 CCM Electronic Control",
      //   specifications: "",
      //   price: "1620",
      //   image: "/images/electronic/elec7.jpg",
      //   type: "0",
      //   select: false,
      //   class: "all",
      // },
    ],
  ],
  [
    ["COMFORT"],
    [
      {
        name: "Heather diesel Webasto",
        specifications: "",
        price: "3500",
        image: "/images/comfort/webasto.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Pressure seawater to the deck",
        specifications: "",
        price: "1000",
        image: "/images/comfort/pressureSeawater.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Aft cabin",
        specifications: "",
        price: "TBD",
        image: "/images/comfort/aftCabin.jpg",
        type: "0",
        select: false,
        class: "cabin",
      },
      {
        name: "2X Underwater lights at stern (e.g. blue)",
        specifications: "",
        price: "1650",
        image: "/images/comfort/underwaterLight.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      // {
      //   name: "4 rodholders",
      //   specifications: "",
      //   price: "220",
      //   image: "/images/comfort/rodholders.jpg",
      //   type: "0",
      //   select: false,
      //   class: "all",
      // },

      {
        name: "Full set of covers for boat",
        specifications: "",
        price: "1000",
        image: "/images/comfort/samba.jpg",
        type: "0",
        select: false,
        class: "all",
      },

      {
        name: "Extra service battery",
        specifications: "",
        price: "350",
        image: "/images/comfort/extraBattery.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      {
        name: "Inlet for shorepower at bow",
        specifications: "",
        price: "450",
        image: "/images/comfort/shorepower.jpg",
        type: "0",
        select: false,
        class: "all",
      },
      // {
      //   name: "Fishing targa arch",
      //   specifications: "",
      //   price: "350",
      //   image: "/images/comfort/samba.jpg",
      //   type: "0",
      //   select: false,
      //   class: "all",
      // },
      // {
      //   name: "Dive door on 1 side",
      //   specifications: "",
      //   price: "TBD",
      //   image: "/images/comfort/samba.jpg",
      //   type: "0",
      //   select: false,
      //   class: "all",
      // },
      // {
      //   name: "Folding bulwark balcony - Port&Stbd",
      //   specifications: "",
      //   price: "TBD",
      //   image: "/images/comfort/samba.jpg",
      //   type: "0",
      //   select: false,
      //   class: "all",
      // },
    ],
  ],
];

export default selectItems;
