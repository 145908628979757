import React from "react";
import CountUp from "../components/CountUp";
const DecideType2 = ({
  setAnimationStartEnd,
  selectedValues,
  setSelectedValues,
}) => {
  // console.log(selectedValues[0][0][1]);

  return (
    <>
      <h4 className="text-lg mt-3 ml-4 lg:ml-1">
        <span className="special-font-bold">{selectedValues[0][2][0][0]}</span>
        <span className="ml-2 special-font-light">SELECTION</span>
      </h4>
      {selectedValues[0][2][0][1] && (
        <h4 className="text-xs ml-4 lg:ml-1 mb-4">
          <span className="special-font-light" style={{ letterSpacing: "0px" }}>
            MANDATORY SELECTION
          </span>
        </h4>
      )}
      <div className="flex w-full flex-row lg:flex-col flex-wrap justify-center items-center">
        {selectedValues[0][2][1]
          .filter((item) => {
            if (
              selectedValues[0][1][1]
                ?.filter((filt) => filt?.select)?.[0]
                ?.name?.includes("Open")
            ) {
              return item.type === "open";
            } else {
              return item.type === "cabin";
            }
          })
          .map((item, index) => {
            return (
              <div
                className="rounded-xl mb-8 mr-0 md:mr-8 lg:mr-0 cursor-pointer"
                key={index}
                style={{
                  maxWidth: "90%",
                  width: "450px",
                  border: `${item?.select ? "2px" : "1px"} solid ${
                    item?.select ? "#252d56" : "rgba(0,0,0,0.3)"
                  }`,
                }}
                onClick={() => {
                  // setAnimationStartEnd(true);
                  let x = [...selectedValues];
                  x[0][2][1] = x[0][2][1].map((boat, i) => {
                    return { ...boat, select: false };
                  });

                  x[0][2][1] = x[0][2][1].map((boat, i) => {
                    if (selectedValues[0][1][1][0].select === true) {
                      if (i === index) {
                        return { ...boat, select: true };
                      } else {
                        return { ...boat, select: false };
                      }
                    } else {
                      if (i - 4 === index) {
                        return { ...boat, select: true };
                      } else {
                        return { ...boat, select: false };
                      }
                    }
                  });
                  setSelectedValues(x);
                  setTimeout(() => {
                    setAnimationStartEnd(false);
                  }, 200);
                }}
              >
                <div className="flex items-center">
                  <div className="flex justify-center transform translate-y-6">
                    <img src={item?.image} alt="" className="w-48" />
                  </div>
                  <div className="w-full flex justify-center transform -translate-x-3">
                    <h3
                      className="special-font-bold"
                      style={{
                        letterSpacing: "0px",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      {item?.name}
                    </h3>
                  </div>
                </div>
                <div className="flex justify-end w-full">
                  <div
                    className="special-font-light w-32 flex"
                    style={{ letterSpacing: "0px" }}
                  >
                    {!isNaN(item.price) && "€"}
                    {!isNaN(item.price) ? (
                      <CountUp endNum={item.price} />
                    ) : (
                      item.price
                    )}
                  </div>
                </div>
                <div className="flex w-full justify-center">
                  <div
                    style={{
                      border: `${item?.select ? "2px" : "1px"} solid ${
                        item?.select ? "#252d56" : "rgba(0,0,0,0.3)"
                      }`,
                    }}
                    className="w-12 h-6 bg-white flex justify-center items-center rounded-lg transform translate-y-3"
                  >
                    {item?.select && (
                      <div
                        className="w-8 h-3 rounded"
                        style={{ background: "#252d56" }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default DecideType2;
