import React, { useState, useEffect } from "react";
import Configurator from "./Configurator";
import Footer from "./Footer";
import NavbarTop from "./NavbarTop";
import decideItems from "../datas/decideItems";
import selectItems from "../datas/selectItems";
let decide = JSON.parse(JSON.stringify(decideItems));
let select = JSON.parse(JSON.stringify(selectItems));
const Layout = () => {
  const [currentNavbarIndex, setCurrentNavbarIndex] = useState(0);
  const [currentNavbarActiveIndex, setCurrentNavbarActiveIndex] = useState(0);
  const [animationStartEnd, setAnimationStartEnd] = useState(false);
  const [pageWidth, setPageWidth] = useState(700);
  const [pageHeight, setPageHeight] = useState(700);
  const [selectedValues, setSelectedValues] = useState([
    [...decide],
    [...select],
  ]);

  useEffect(() => {
    setSelectedValues([[...decide], [...select]]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    selectedValues[0]?.[3]?.[1]?.forEach((item, index) => {
      // console.log(index, item?.select);
    });
  }, [selectedValues]);

  useEffect(() => {
    // console.log(currentNavbarIndex);
  }, [currentNavbarIndex]);

  const [values, setValues] = useState([
    { title: "First Name", value: "", type: "text", id: "name" },
    { title: "Last Name", value: "", type: "text", id: "surname" },
    { title: "Email Address", value: "", type: "email", id: "email" },
    { title: "Phone Number", value: "", type: "text", id: "phone" },
    { title: "Where are you boating?", value: "", type: "text", id: "where" },
    {
      title: "Preferred Delivery Date",
      value: "",
      type: "text",
      id: "delivery",
    },
    { title: "Address", value: "", type: "text", id: "address" },
  ]);

  const scrolledPage = () => {
    setPageWidth(window.innerWidth);
    setPageHeight(window.innerHeight);
    console.log(pageWidth);
    // setPageYPosition(window.pageYOffset);
  };

  useEffect(() => {
    scrolledPage();
    window.addEventListener("resize", scrolledPage);
    // window.addEventListener("scroll", scrolledPage);
    return () => {
      window.removeEventListener("resize", scrolledPage);
      // window.removeEventListener("scroll", scrolledPage);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="overflow-auto">
        <NavbarTop
          currentNavbarIndex={currentNavbarIndex}
          setCurrentNavbarIndex={setCurrentNavbarIndex}
          currentNavbarActiveIndex={currentNavbarActiveIndex}
          setCurrentNavbarActiveIndex={setCurrentNavbarActiveIndex}
          setAnimationStartEnd={setAnimationStartEnd}
        />
        <Configurator
          currentNavbarIndex={currentNavbarIndex}
          setCurrentNavbarIndex={setCurrentNavbarIndex}
          pageHeight={pageHeight}
          animationStartEnd={animationStartEnd}
          setAnimationStartEnd={setAnimationStartEnd}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setValues={setValues}
          values={values}
        />
      </div>

      <Footer
        currentNavbarIndex={currentNavbarIndex}
        setCurrentNavbarIndex={setCurrentNavbarIndex}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        setValues={setValues}
        values={values}
      />
    </>
  );
};

export default Layout;
