const decideItems = [
  [
    // 0-0
    ["BOAT", true],
    // 0-1
    [
      {
        name: "Samba 11",
        specifications1:
          "Samba 11 T-TOP is for sun lovers and watersports enthusiasts. There is an aft seating option as well as a sunpad option under a permanently fixed rigid T Top covering the helm area and partially aft.",
        specifications2:
          "Forward cabin houses accommodation for two and a seperate enclosed toilet compartment with shower so you can embark on that occasional long distance, overnight trip to your favourite coastal destination.",
        price: "Base",
        image: "/images/openBoatBig.jpg",
        select: true,
        id: 1,
      },
      // {
      //   name: "Samba Explorer",
      //   specifications1:
      //     "With fully enclosed cabin and port and starboard entry doors Samba11 Cabin provides protection in both hot and cold climates for maximum enjoyment of boating in all weather conditions and all seasons hence truly living upto its design philosopy Seamore.",
      //   specifications2:
      //     "The boat offers a comfortable and roomy, no compromise interior space that seats upto 7 around a large and sociable but removable dining table.",
      //   price: "Base",
      //   image: "/images/explorerBoat.jpg",
      //   select: false,
      //   id: 2,
      // },
      // {
      //   name: "Samba 9",
      //   specifications1:
      //     "With fully enclosed cabin and port and starboard entry doors Samba11 Cabin provides protection in both hot and cold climates for maximum enjoyment of boating in all weather conditions and all seasons hence truly living upto its design philosopy Seamore.",
      //   specifications2:
      //     "The boat offers a comfortable and roomy, no compromise interior space that seats upto 7 around a large and sociable but removable dining table.",
      //   price: "Base",
      //   image: "/images/samba9Cabin.jpg",
      //   select: false,
      //   id: 3,
      // },
    ],
  ],
  [
    // 1-0
    ["TYPE", true],
    // 1-1
    [
      {
        name: "Samba 11 Open Boat",
        specifications1:
          "Samba 11 T-TOP is for sun lovers and watersports enthusiasts. There is an aft seating option as well as a sunpad option under a permanently fixed rigid T Top covering the helm area and partially aft.",
        specifications2:
          "Forward cabin houses accommodation for two and a seperate enclosed toilet compartment with shower so you can embark on that occasional long distance, overnight trip to your favourite coastal destination.",
        price: 234850,
        image: "/images/openBoatBig.jpg",
        select: true,
        id: 1,
        type: "open",
      },
      {
        name: "Samba 11 Cabin Boat",
        specifications1:
          "With fully enclosed cabin and port and starboard entry doors Samba 11 Cabin provides protection in both hot and cold climates for maximum enjoyment of boating in all weather conditions and all seasons hence truly living upto its design philosopy Seamore.",
        specifications2:
          "The boat offers a comfortable and roomy, no compromise interior space that seats upto 7 around a large and sociable but removable dining table.",
        price: 244825,
        image: "/images/cabinBoatBig.jpg",
        select: false,
        id: 1,
        type: "cabin",
      },
      // {
      //   name: "Samba Explorer Open Boat",
      //   specifications1:
      //     "Samba 11 T-TOP is for sun lovers and watersports enthusiasts. There is an aft seating option as well as a sunpad option under a permanently fixed rigid T Top covering the helm area and partially aft.",
      //   specifications2:
      //     "Forward cabin houses accommodation for two and a seperate enclosed toilet compartment with shower so you can embark on that occasional long distance, overnight trip to your favourite coastal destination.",
      //   price: 202750,
      //   image: "/images/explorerBoat.jpg",
      //   select: false,
      //   id: 2,
      //   type: "open",
      // },
      // {
      //   name: "Samba 9 Open Boat",
      //   specifications1:
      //     "Samba 11 T-TOP is for sun lovers and watersports enthusiasts. There is an aft seating option as well as a sunpad option under a permanently fixed rigid T Top covering the helm area and partially aft.",
      //   specifications2:
      //     "Forward cabin houses accommodation for two and a seperate enclosed toilet compartment with shower so you can embark on that occasional long distance, overnight trip to your favourite coastal destination.",
      //   price: 202750,
      //   image: "/images/samba9Cabin.jpg",
      //   select: false,
      //   id: 3,
      //   type: "open",
      // },
      // {
      //   name: "Samba 9 Cabin Boat",
      //   specifications1:
      //     "With fully enclosed cabin and port and starboard entry doors Samba 11 Cabin provides protection in both hot and cold climates for maximum enjoyment of boating in all weather conditions and all seasons hence truly living upto its design philosopy Seamore.",
      //   specifications2:
      //     "The boat offers a comfortable and roomy, no compromise interior space that seats upto 7 around a large and sociable but removable dining table.",
      //   price: 209500,
      //   image: "/images/samba9Cabin.jpg",
      //   select: false,
      //   id: 3,
      //   type: "cabin",
      // },
    ],
  ],
  [
    // 2 - 0
    ["ENGINE", true],
    // 2 - 1
    [
      {
        name: "2 x Mercury Verado 300 HP V8 4.6L Open",
        specifications: "",
        price: "Standard",
        image: "/images/engines/open1.jpg",
        type: "open",
        select: true,
      },
      {
        name: "2 x Mercury Verado 350 HP Inline6 2.6L Open",
        specifications: "",
        price: "8250",
        image: "/images/engines/open2.jpg",
        type: "open",
        select: false,
      },
      {
        name: "2 x Yamaha 300 HP V6 4.2L Open",
        specifications: "",
        price: "14630",
        image: "/images/engines/open3.jpg",
        type: "open",
        select: false,
      },
      {
        name: "2 x Suzuki 300 HP 4.0L Open",
        specifications: "",
        price: "7150",
        image: "/images/engines/open4.jpg",
        type: "open",
        select: false,
      },
      {
        name: "2 x Mercury Verado 300 HP V8 4.6L Cabin",
        specifications: "",
        price: "Standard",
        image: "/images/engines/cabin1.jpg",
        type: "cabin",
        select: false,
      },
      {
        name: "2 x Mercury Verado 350 HP Inline6 2.6L Cabin",
        specifications: "",
        price: "8250",
        image: "/images/engines/cabin2.jpg",
        type: "cabin",
        select: false,
      },
      {
        name: "2 x Yamaha 300 HP V6 4.2L Cabin",
        specifications: "",
        price: "14630",
        image: "/images/engines/cabin4.jpg",
        type: "cabin",
        select: false,
      },
      {
        name: "2 x Suzuki 300 HP 4.0L Cabin",
        specifications: "",
        price: "7150",
        image: "/images/engines/cabin3.jpg",
        type: "cabin",
        select: false,
      },
    ],
  ],
  [
    ["COLOR", true],
    [
      {
        name: "Open White",
        specifications: "",
        price: "Standard",
        image: "/images/colors/openWhite.jpg",
        type: "open",
        select: true,
        id: 1,
      },
      {
        name: "Open Black",
        specifications: "",
        price: "2000",
        image: "/images/colors/openBlack.jpg",
        type: "open",
        select: false,
        id: 1,
      },
      {
        name: "Open Grey",
        specifications: "",
        price: "2000",
        image: "/images/colors/openGrey.jpg",
        type: "open",
        select: false,
        id: 1,
      },
      {
        name: "Open Midnight Blue",
        specifications: "",
        price: "2000",
        image: "/images/colors/openBlue.jpg",
        type: "open",
        select: false,
        id: 1,
      },
      {
        name: "Open Red",
        specifications: "",
        price: "2000",
        image: "/images/colors/openRed.jpg",
        type: "open",
        select: false,
        id: 1,
      },
      {
        name: "Cabin White",
        specifications: "",
        price: "Standard",
        image: "/images/colors/cabinWhite.jpg",
        type: "cabin",
        select: false,
        id: 1,
      },
      {
        name: "Cabin Blue",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinBlue.jpg",
        type: "cabin",
        select: false,
        id: 1,
      },
      {
        name: "Cabin Red",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinRed.jpg",
        type: "cabin",
        select: false,
        id: 1,
      },
      {
        name: "Cabin Midnight Grey",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinGrey.jpg",
        type: "cabin",
        select: false,
        id: 1,
      },
      {
        name: "Cabin Black",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinBlack.jpg",
        type: "cabin",
        select: false,
        id: 1,
      },
      // 2
      {
        name: "Open White",
        specifications: "",
        price: "Standard",
        image: "/images/colors/openWhite.jpg",
        type: "open",
        select: false,
        id: 2,
      },
      {
        name: "Open Black",
        specifications: "",
        price: "2000",
        image: "/images/colors/openBlack.jpg",
        type: "open",
        select: false,
        id: 2,
      },
      {
        name: "Open Grey",
        specifications: "",
        price: "2000",
        image: "/images/colors/openGrey.jpg",
        type: "open",
        select: false,
        id: 2,
      },
      {
        name: "Open Midnight Blue",
        specifications: "",
        price: "2000",
        image: "/images/colors/openBlue.jpg",
        type: "open",
        select: false,
        id: 2,
      },
      {
        name: "Open Red",
        specifications: "",
        price: "2000",
        image: "/images/colors/openRed.jpg",
        type: "open",
        select: false,
        id: 2,
      },
      {
        name: "Cabin White",
        specifications: "",
        price: "Standard",
        image: "/images/colors/cabinWhite.jpg",
        type: "cabin",
        select: false,
        id: 2,
      },
      {
        name: "Cabin Blue",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinBlue.jpg",
        type: "cabin",
        select: false,
        id: 2,
      },
      {
        name: "Cabin Red",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinRed.jpg",
        type: "cabin",
        select: false,
        id: 2,
      },
      {
        name: "Cabin Midnight Grey",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinGrey.jpg",
        type: "cabin",
        select: false,
        id: 2,
      },
      {
        name: "Cabin Black",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinBlack.jpg",
        type: "cabin",
        select: false,
        id: 2,
      },
      // 3
      {
        name: "Open White",
        specifications: "",
        price: "Standard",
        image: "/images/colors/openWhite.jpg",
        type: "open",
        select: false,
        id: 3,
      },
      {
        name: "Open Black",
        specifications: "",
        price: "2000",
        image: "/images/colors/openBlack.jpg",
        type: "open",
        select: false,
        id: 3,
      },
      {
        name: "Open Grey",
        specifications: "",
        price: "2000",
        image: "/images/colors/openGrey.jpg",
        type: "open",
        select: false,
        id: 3,
      },
      {
        name: "Open Midnight Blue",
        specifications: "",
        price: "2000",
        image: "/images/colors/openBlue.jpg",
        type: "open",
        select: false,
        id: 3,
      },
      {
        name: "Open Red",
        specifications: "",
        price: "2000",
        image: "/images/colors/openRed.jpg",
        type: "open",
        select: false,
        id: 3,
      },
      {
        name: "Cabin White",
        specifications: "",
        price: "Standard",
        image: "/images/colors/cabinWhite.jpg",
        type: "cabin",
        select: false,
        id: 3,
      },
      {
        name: "Cabin Blue",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinBlue.jpg",
        type: "cabin",
        select: false,
        id: 3,
      },
      {
        name: "Cabin Red",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinRed.jpg",
        type: "cabin",
        select: false,
        id: 3,
      },
      {
        name: "Cabin Midnight Grey",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinGrey.jpg",
        type: "cabin",
        select: false,
        id: 3,
      },
      {
        name: "Cabin Black",
        specifications: "",
        price: "2000",
        image: "/images/colors/cabinBlack.jpg",
        type: "cabin",
        select: false,
        id: 3,
      },
    ],
  ],
  [
    ["FLOOR", true],
    [
      {
        name: "Anti skid painted floor",
        specifications: "",
        price: "Standard",
        image: "/images/floor/floor1.jpg",
        type: "all",
        select: true,
      },
      {
        name: "Flexiteek Decking",
        specifications: "",
        price: "9000",
        image: "/images/floor/floor2.jpg",
        type: "all",
        select: false,
      },
      {
        name: "Eva Deck Floor",
        specifications: "",
        price: "9000",
        image: "/images/floor/floor3.jpg",
        type: "all",
        select: false,
      },
      {
        name: "Teak Deck Floor",
        specifications: "",
        price: "12000",
        image: "/images/floor/floor4.jpg",
        type: "all",
        select: false,
      },
    ],
  ],
];
export default decideItems;
