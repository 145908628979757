import React, { useState, useEffect } from "react";
import DecideType0 from "../components/DecideType0";
import DecideType1 from "../components/DecideType1";
import DecideType2 from "../components/DecideType2";
import DecideType3 from "../components/DecideType3";
import DecideType4 from "../components/DecideType4";
import Review from "../components/Review";
import navbarItems from "../datas/navbarItems";
const Decide = ({
  currentNavbarIndex,
  setAnimationStartEnd,
  selectedValues,
  setSelectedValues,
  setCurrentNavbarIndex,
}) => {
  const [selectedBoatImage, setSelectedBoatImage] = useState(
    "/images/openBoatBig.jpg"
  );

  useEffect(() => {
    selectedValues[0][1][1].forEach((boat) => {
      if (boat?.select) {
        setSelectedBoatImage(boat?.image);
      }
    });
  }, [selectedValues]);

  return (
    <div className="lg:h-full flex w-full">
      <div className="flex-1 hidden border-r border-gray-400 lg:flex flex-col justify-center items-center">
        {currentNavbarIndex === 8 && (
          <div className="w-full flex justify-center">
            <h3
              className="absolute special-font-bold text-3xl"
              style={{ letterSpacing: "0px" }}
            >
              Your order is ready to go!
            </h3>
          </div>
        )}
        <img src={selectedBoatImage} alt="" className="w-full max-w-3xl" />
      </div>
      <div className="lg:hidden w-full">
        <div className="w-full text-xl border-t border-b border-gray-400 p-4 special-font-bold">
          {navbarItems[0]}
        </div>
        <DecideType0
          setAnimationStartEnd={setAnimationStartEnd}
          setSelectedBoatImage={setSelectedBoatImage}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
        <div className="w-full text-xl border-t border-b border-gray-400 p-4 special-font-bold">
          {navbarItems[1]}
        </div>
        <DecideType1
          setAnimationStartEnd={setAnimationStartEnd}
          setSelectedBoatImage={setSelectedBoatImage}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
        <div className="w-full text-xl border-t border-b border-gray-400 p-4 special-font-bold">
          {navbarItems[2]}
        </div>
        <DecideType2
          setAnimationStartEnd={setAnimationStartEnd}
          setSelectedBoatImage={setSelectedBoatImage}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
        <div className="w-full text-xl border-t border-b border-gray-400 p-4 special-font-bold">
          {navbarItems[3]}
        </div>
        <DecideType3
          setAnimationStartEnd={setAnimationStartEnd}
          setSelectedBoatImage={setSelectedBoatImage}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
        <div className="w-full text-xl border-t border-b border-gray-400 p-4 special-font-bold">
          {navbarItems[4]}
        </div>
        <DecideType4
          setAnimationStartEnd={setAnimationStartEnd}
          setSelectedBoatImage={setSelectedBoatImage}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
      </div>
      <div className="lg:w-1/2 hidden lg:block lg:max-w-lg pt-8 px-4 h-full overflow-auto">
        {currentNavbarIndex === 0 && (
          <DecideType0
            setAnimationStartEnd={setAnimationStartEnd}
            setSelectedBoatImage={setSelectedBoatImage}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        )}
        {currentNavbarIndex === 1 && (
          <DecideType1
            setAnimationStartEnd={setAnimationStartEnd}
            setSelectedBoatImage={setSelectedBoatImage}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        )}
        {currentNavbarIndex === 2 && (
          <DecideType2
            setAnimationStartEnd={setAnimationStartEnd}
            setSelectedBoatImage={setSelectedBoatImage}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        )}
        {currentNavbarIndex === 3 && (
          <DecideType3
            setAnimationStartEnd={setAnimationStartEnd}
            setSelectedBoatImage={setSelectedBoatImage}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        )}
        {currentNavbarIndex === 4 && (
          <DecideType4
            setAnimationStartEnd={setAnimationStartEnd}
            setSelectedBoatImage={setSelectedBoatImage}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        )}
        {currentNavbarIndex === 8 && (
          <Review
            setAnimationStartEnd={setAnimationStartEnd}
            setSelectedBoatImage={setSelectedBoatImage}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            setCurrentNavbarIndex={setCurrentNavbarIndex}
          />
        )}
      </div>
    </div>
  );
};

export default Decide;
