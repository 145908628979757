const navbarItems = [
  "BOAT",
  "TYPE",
  "ENGINE",
  "COLOR",
  "FLOOR",
  "FUNCTIONALITY",
  "ELECTRONIC",
  "COMFORT",
  "REVIEW",
];
export default navbarItems;
