import React from "react";
import navbarItems from "../datas/navbarItems";
import CountUp from "../components/CountUp";
const SelectType1 = ({
  currentNavbarIndex,
  selectedValues,
  setSelectedValues,
}) => {
  const handleChangeCount = (way, index) => {
    let x = [...selectedValues];

    if (way === "-") {
      // window.alert(index);
      if (
        x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][index]
          .select &&
        x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][index]
          .multi &&
        x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][index]
          .count > 1
      ) {
        x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][
          index
        ].count =
          x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][index]
            .count - 1;
      }
    } else {
      if (
        x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][index]
          .select &&
        x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][index]
          .multi &&
        x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][index]
          .count < 4
      ) {
        x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][
          index
        ].count =
          x[1][currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0][1][index]
            .count + 1;
      }
    }
    setSelectedValues(x);
  };

  return (
    <>
      <div className="flex">
        <div className="w-full h-full">
          <div className="w-full text-xl border-t border-b border-gray-400 p-4 special-font-bold">
            {navbarItems[currentNavbarIndex]}
          </div>
          <div>
            <h4 className="text-lg mt-3 ml-4">
              <span className="special-font-bold">
                {
                  selectedValues[1][
                    currentNavbarIndex > 4 && currentNavbarIndex < 8
                      ? currentNavbarIndex - 5
                      : 0
                  ][0][0]
                }
              </span>
              <span className="ml-2 special-font-light"></span>
            </h4>
            {selectedValues[0][0][0][1] && (
              <h4 className="text-xs ml-4 mb-4">
                <span
                  className="special-font-light"
                  style={{ letterSpacing: "0px" }}
                >
                  OPTIONS
                </span>
              </h4>
            )}
          </div>
          <div className="w-full flex flex-wrap justify-around">
            {selectedValues[1][
              currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0
            ][1]
              // .filter((item, index) => {
              //   if (item.class === "all") {
              //     return true;
              //   }
              //   if (selectedValues[0][0][1][0].select) {
              //     if (item.class === "open") {
              //       return true;
              //     }
              //   } else {
              //     if (item.class === "cabin") {
              //       return true;
              //     }
              //   }
              //   return false;
              // })
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex mx-4 mt-4 mb-8 flex-col justify-between items-center"
                    style={{
                      display:
                        selectedValues[0][1][1]?.filter(
                          (filt) => filt?.select
                        )?.[0]?.type === "open" &&
                        (item.class === "open" || item.class === "all")
                          ? `flex`
                          : selectedValues[0][1][1]?.filter(
                              (filt) => filt?.select
                            )?.[0]?.type === "cabin" &&
                            (item.class === "cabin" || item.class === "all")
                          ? "flex"
                          : "none",
                    }}
                  >
                    <div
                      className="rounded-xl cursor-pointer flex flex-col justify-between h-full"
                      style={{
                        maxWidth: "90%",
                        width: "300px",
                        border: `${item?.select ? "2px" : "1px"} solid ${
                          item?.select ? "#252d56" : "rgba(0,0,0,0.3)"
                        }`,
                      }}
                      onClick={() => {
                        let x = [...selectedValues];
                        if (item.type !== "0") {
                          x[1][
                            currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0
                          ][1] = x[1][
                            currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0
                          ][1].map((opt, i) => {
                            if (opt.type === item.type && i !== index) {
                              return { ...opt, select: false };
                            } else {
                              return { ...opt };
                            }
                          });
                        }
                        x[1][
                          currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0
                        ][1][index].select =
                          !x[1][
                            currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0
                          ][1][index].select;
                        if (
                          x[1][
                            currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0
                          ][1][index].select &&
                          x[1][
                            currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0
                          ][1][index].multi
                        ) {
                          x[1][
                            currentNavbarIndex > 5 ? currentNavbarIndex - 5 : 0
                          ][1][index].count = 1;
                        }
                        setSelectedValues(x);
                      }}
                    >
                      <div>
                        <img
                          src={item?.image}
                          alt=""
                          className="w-full transform translate-y-2"
                        />
                        <div
                          className="mt-6 p-2 special-font-bold transform translate-y-3 text-center"
                          style={{ letterSpacing: "1px" }}
                        >
                          {item?.name}
                        </div>
                      </div>
                      <div className="flex flex-col items-center w-full justify-between">
                        <div className="mt-3 w-full flex items-end flex-col justify-end pr-3">
                          <div className="flex">
                            {!isNaN(item.price) && "€"}
                            {!isNaN(item.price) ? (
                              <CountUp endNum={item.price} />
                            ) : (
                              item.price
                            )}
                            {item.multi && (
                              <span className="ml-2"> / each</span>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            border: `${item?.select ? "2px" : "1px"} solid ${
                              item?.select ? "#252d56" : "rgba(0,0,0,0.3)"
                            }`,
                          }}
                          className="w-12 h-6 bg-white flex justify-center items-center rounded-lg transform translate-y-3"
                        >
                          {item?.select && (
                            <div
                              className="w-8 h-3 rounded"
                              style={{ background: "#252d56" }}
                            ></div>
                          )}
                        </div>
                      </div>
                    </div>
                    {item.multi && item.select && (
                      <div className="transform -translate-y-12 -translate-x-24">
                        <div className="absolute transform -translate-y-2 -translate-x-5">
                          <div className="w-32">Total: {item.count}</div>
                          <div className="flex">
                            <button
                              className="ml-1 mr-2  border border-red-600 w-5 h-5 flex items-center justify-center text-red-600 rounded-full"
                              onClick={() => {
                                handleChangeCount("-", index);
                              }}
                            >
                              -
                            </button>
                            <button
                              className="mr-2 border border-green-600 w-5 h-5 flex items-center justify-center text-green-600 rounded-full"
                              onClick={() => {
                                handleChangeCount("+", index);
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectType1;
